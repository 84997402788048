import { useEffect, useMemo, useState } from "react";
import { Platform, View } from "react-native";

import { makeStyles } from "@rneui/themed";
import DeviceInfo from "react-native-device-info";
import VersionCheck, {
  ReactNativeVersionCheck
} from "react-native-version-check";
import { DateTime } from "luxon";

import EnvVars from "common/config/EnvVars";
import Text from "./ui/Text";
import Spacing from "./ui/Spacing";
import Button from "./ui/Button";
import { Linking_openURL } from "../helpers/LinkingHelper";
import { useAppDispatch } from "common/redux";
import LocalizedStrings from "../helpers/LocalizedStrings";

const AppVersion = () => {
  const styles = useStyles();
  const dispatch = useAppDispatch();

  const [version, setVersion] = useState<ReactNativeVersionCheck>();

  useEffect(() => {
    if (Platform.OS === "web") return;

    VersionCheck.needUpdate()
      .then((version) => setVersion(version))
      .catch((error) => console.log(error));
  }, []);

  const upgradeHandler = () => {
    Linking_openURL(version?.storeUrl, dispatch);
  };

  const buildDateTimeString = useMemo(() => {
    if (EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV === "prod") return null;
    if (EnvVars.REACT_APP_BUILD_DATETIME?.length < 4) return null;

    const dateTime = DateTime.fromISO(EnvVars.REACT_APP_BUILD_DATETIME);
    if (!dateTime.isValid) return null;

    return dateTime.toFormat("MM/dd/yyyy hh:mm a");
  }, []);

  return (
    <View style={styles.container}>
      <Text body style={styles.versionTextColor}>
        {Platform.OS === "web" &&
          buildDateTimeString &&
          `${LocalizedStrings.common.appVersion.lastUpdated} ${buildDateTimeString}`}
        {Platform.OS !== "web" &&
          `${LocalizedStrings.common.appVersion.version} ${DeviceInfo.getVersion()}\n${LocalizedStrings.common.appVersion.buildNumber} ${DeviceInfo.getBuildNumber()}`}
        {EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV !== "prod" &&
          `\n(${EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV})`}
      </Text>
      {Platform.OS !== "web" && version?.isNeeded && (
        <>
          <Spacing vertical={3} />
          <Button
            title={LocalizedStrings.common.appVersion.upgrade}
            onPress={upgradeHandler}
          />
        </>
      )}
    </View>
  );
};

const MARGIN_SIDES = 10;

const useStyles = makeStyles((theme) => {
  return {
    container: {
      alignItems: "flex-end",
      marginHorizontal: MARGIN_SIDES
    },
    versionTextColor: {
      color: theme.colors.grey2,
      alignSelf: "flex-end",
      textAlign: "right",
      marginTop: 15
    }
  };
});

export default AppVersion;
